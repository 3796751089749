import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Heading, Box, PseudoBox, Flex, Tag, Text } from "@chakra-ui/core"

export default ({ post }) => {
  const { title, path, description, date, featuredImage, tags } = post
  let featuredImgFluid =
    featuredImage !== null ? featuredImage.childImageSharp.fluid : null
  return (
    <PseudoBox
      border="solid 1px #EEE"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="0px 1px 4px rgba(0, 0, 0, 0.18)"
      transition="transform .2s"
      _hover={{ transform: "scale(1.015)", boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.14)" }}
    >
      {featuredImgFluid !== null && (
        <Link to={path}>
          <Img fluid={featuredImgFluid} />
        </Link>
      )}
      <Box padding="0.8em">
        <Flex justify="space-between" align="flex-end">
          <Tag border="solid 1px gray" background="none" size="sm" color="GOLDENROD">
            {Array.isArray(tags) && tags.length > 0 && tags.find(f => f)}
          </Tag>
          <Text color="grey">{date}</Text>
        </Flex>
        <Link to={path}>
          <Box paddingY={2}>
            <Heading size="lg" fontWeight="900">{title}</Heading>
            <Text color="grey">{description}</Text>
          </Box>
        </Link>
      </Box>
    </PseudoBox>
  )
}
