import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Box, Grid, Heading, Divider } from "@chakra-ui/core"

import PostThumbnail from "../components/PostThumbnail"
import UnderConstruction from '../components/UnderConstruction'
const Blog = ({ data }) => (
  <Layout>
    {/* <UnderConstruction /> */}
    <Heading>Recipes</Heading>
    <Divider borderColor="gray.200" />
    <Grid templateColumns={{ lg: "repeat(3, 1fr)", md: "repeat(2, 1fr)", sm: "repeat(1, 1fr)" }} gap={6} padding="1em 0">
      {data.allMarkdownRemark.edges.map((edge, index) => {
        const post = edge.node.frontmatter
        return (
            <PostThumbnail post={post} key={index}/>
        )
      })}
    </Grid>
  </Layout>
)

export default Blog

export const AllBlogsQuery = graphql`
  {
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: [DESC]}) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            description
            tags
            path
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 450, maxHeight: 255, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
